import React, { useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { useAxios } from "../../utils/hook/useAxios";

function CreateWebshopInput({
  handleSelectWebshop,
  values,
  handleChange,
  errors,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [checkedurl, setCheckedurl] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const axios = useAxios();

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    setCheckedurl(isURL(inputValue));

    // Send a request to the server to search for categories matching the input value
    if (
      inputValue.length >= 2 &&
      inputValue !== "www." &&
      inputValue !== "www" &&
      inputValue !== "ww"
    ) {
      fetch(
        `https://api.veritatrust.com/v1/merchant_profiles/search-results/${inputValue}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data);
          setShow(true);
        })
        .catch((error) => {
          console.error(error);
          setSearchResults([]);
        });
    } else {
      setSearchResults([]);
      setShow(false);
    }
  };

  const handleSelectWebshopInput = (suggestion) => {
    setSearchResults([]);
    setSearchTerm(suggestion.website);
    setShow(false);
    handleSelectWebshop(suggestion);

    axios
      .post(`/subscriptions`, {
        subscriptionPlan: "0",
        expirationDate: "2099-01-01 00:00:00",
        apiToken:
          "118063ce083ae2731791a5674aba9de0321c5d32e3715a2e01f27b4795b1389b",
        status: 1,
        MonthlyReview: 200,
        EmailInviteCustom: 0,
        CustomerSupport: "0",
        InviteFromPastCustomers: 0,
        dashboard: "0",
        QandAonProductPage: 0,
        LocalReviews: 0,
        MultiUserDomain: "0",
        NetPromoterScore: 0,
        GoogleShoppingSEOReviewWidget: 0,
        merchandId: suggestion.id,
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  function handleAddNewWebshop() {
    axios
      .post(`/merchant-profile`, { name: searchTerm, website: searchTerm })
      .then((res) => {
        if (searchTerm.length >= 1) {
          fetch(
            `https://api.veritatrust.com/v1/merchant_profiles/search-results/${searchTerm}`
          )
            .then((response) => response.json())
            .then((data) => {
              setSearchResults(data);

              setShow(true);
            })
            .catch((error) => {
              setSearchResults([]);

              console.error(error);
            });
        } else {
          setSearchResults([]);
          setShow(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function isURL(val) {
    var regex = /^(www\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,6}$/;

    return regex.test(val);
  }

  return (
    <Form.Group controlId="validationFormikWebsite">
      <InputGroup hasValidation>
        <FloatingLabel
          controlId="floatingWebsiteInput"
          label="Website"
          className=""
        >
          <Form.Control
            type="text"
            placeholder="website"
            aria-describedby="inputGroupPrepend"
            name="website"
            value={searchTerm}
            onChange={handleInputChange}
            isInvalid={!!errors.website}
            onBlur={() => {
              setTimeout(() => setShow(false), 200);
            }}
            onFocus={() => {
              setShow(true);
            }}
          />
        </FloatingLabel>
        <Form.Control.Feedback type="invalid">
          {errors.website}
        </Form.Control.Feedback>
      </InputGroup>
      {searchTerm.length >= 2 && (
        <ul className={show ? "dropdown-menu show" : "dropdown-menu"}>
          {searchResults.length > 0 &&
            searchResults?.map((suggestion, index) => (
              <li key={index}>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => handleSelectWebshopInput(suggestion)}
                >
                  {suggestion.website}
                </button>
              </li>
            ))}
          {searchTerm.length >= 2 && (
            <>
              <li>
                <p className="dropdown-header">Webshop not found ? </p>
              </li>
              {!checkedurl && (
                <li>
                  <p className="dropdown-header callout callout-error mt-1">
                    Not Url Format
                  </p>
                </li>
              )}
              <li>
                <button
                  className="dropdown-item active"
                  type="button"
                  onClick={handleAddNewWebshop}
                  disabled={!checkedurl}
                >
                  Create a new webshop
                </button>
              </li>
            </>
          )}
        </ul>
      )}
    </Form.Group>
  );
}

export default CreateWebshopInput;
